import React, { useEffect } from 'react'
import Map from '../components/Contact/Map'
import Layout from '../components/Layout'
import Hero from '../components/Mobileapp/hero'
import Seo from '../components/Seo';
import RequestForm from '../components/requestOfQuote/RequestForm';
import GetInTought from '../components/requestOfQuote/GetInTought';
import allApiData from '../api';
import SeoheadData from '../siteScriptData';


export default function RequestOfQuote() {

  useEffect(() => {
    allApiData.aosinit();
  }, []);


  return (
    <Layout>
      <Seo SeoData={SeoheadData.quotationPageSeoData}></Seo>
      <Hero title="Request for Quote" def="We provide customized pricing for each project and request we receive. Fill the form below and receive a personalized quote for your requirements." button_t={false} btnqua={false}>

        <div className="hero-img hide-animation-768">
          <img src="../assets/img/request-of-quote/hero_img/request.svg" className=" img-fluid" alt="request" />
          <img src="../assets/img/request-of-quote/hero_img/1.svg" className=" request-img1 img-fluid" alt="request-one" />
          <img src="../assets/img/request-of-quote/hero_img/2.svg" className="request-img2 img-fluid" alt="request-two" />
          <img src="../assets/img/request-of-quote/hero_img/3.svg" className=" request-img3 img-fluid" alt="request-three" />
        </div>
        <div className="show-main-img-768">
          <img src="../assets/img/request-of-quote/hero_img/request_for_mobile.svg" className=" img-fluid" alt="request_for_mobile" />
        </div>

      </Hero>

      <RequestForm />

      <GetInTought />

      <Map />

    </Layout>
  )
}