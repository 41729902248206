import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import { IoMdClose } from "react-icons/io";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { requestForm } from "../../api";
import { toast } from "react-toastify";
import image from "../../assets/document.png";
import Loader from "../../assets/form_loader.gif";

const RequestForm = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    pre_mobile: "",
    mobile: "",
    service: "",
    budget: "",
    start_at: "",
    requirement: "",
    description: "",
  });
  const [formErrors, setFormErrors] = useState({});

  // useEffect(() => {
  //   try {
  //     var f = document.createElement("iframe");
  //     f.src =
  //       "https://forms.zohopublic.in/vasundharainfotech/form/REQUESTFORQUOTE1/formperma/MsOqjiUmtnHE55Djqqo1lu3Kni6_6rDKWxSZ5RD4ZLA?zf_rszfm=1";
  //     f.style.border = "none";
  //     f.style.height = "1739px";
  //     f.style.width = "90%";
  //     f.style.transition = "all 0.5s ease";
  //     var d = document.getElementById(
  //       "zf_div_MsOqjiUmtnHE55Djqqo1lu3Kni6_6rDKWxSZ5RD4ZLA"
  //     );
  //     d.appendChild(f);
  //     window.addEventListener(
  //       "message",
  //       function (event) {
  //         console.log(event.data, "eventttttt");
  //         var evntData = event.data;
  //         if (evntData && evntData.constructor == String) {
  //           var zf_ifrm_data = evntData.split("|");
  //           if (zf_ifrm_data.length == 2) {
  //             var zf_perma = zf_ifrm_data[0];
  //             var zf_ifrm_ht_nw = parseInt(zf_ifrm_data[1], 10) + 15 + "px";
  //             var iframe = document
  //               .getElementById(
  //                 "zf_div_MsOqjiUmtnHE55Djqqo1lu3Kni6_6rDKWxSZ5RD4ZLA"
  //               )
  //               .getElementsByTagName("iframe")[0];
  //             if (
  //               iframe.src.indexOf("formperma") > 0 &&
  //               iframe.src.indexOf(zf_perma) > 0
  //             ) {
  //               var prevIframeHeight = iframe.style.height;
  //               if (prevIframeHeight != zf_ifrm_ht_nw) {
  //                 iframe.style.height = zf_ifrm_ht_nw;
  //               }
  //             }
  //           }
  //         }
  //       },
  //       false
  //     );
  //   } catch (e) {}
  // }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  // console.log(form);

  const onSubmit = async () => {
    try {
      // console.log("submit",form);
      setLoading(true);
      const errors = {};

      if (!form.first_name) {
        errors.first_name = "Enter a value for this field.";
      }
      if (!form.last_name) {
        errors.last_name = "Enter a value for this field.";
      }
      if (!form.email) {
        errors.email = "Enter a value for this field.";
        // errors.email = "Enter a valid email address. (eg: yourname@domain.com)";
      }
      if (!form.mobile) {
        // errors.mobile = "Enter only numbers.";
        errors.mobile = "Enter a number for this field.";
      }
      if (!form.service) {
        errors.service = "Select a choice.";
      }
      if (!form.budget) {
        errors.budget = "Select a choice.";
      }
      if (!form.start_at) {
        errors.start_at = "Select a choice.";
      }
      if (!form.requirement) {
        errors.requirement = "Select a choice.";
      }
      if (form.last_name) {
        form.last_name = form.last_name.trim();
      }
      if (form.first_name) {
        form.first_name = form.first_name.trim();
      }
      if (form.email) {
        const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}/;
        if (!emailRegex.test(form.email)) {
          errors.email =
            "Enter a valid email address. (eg: yourname@domain.com)";
        }
      }

      setFormErrors(errors);

      if (Object.keys(errors).length === 0) {
        console.log("submit", form);

        const formData = new FormData();
        for (const key in form) {
          if (form.hasOwnProperty(key)) {
            formData.append(key, form[key]);
          }
        }

        if (selectedFiles.length > 0) {
          selectedFiles.forEach((file, index) => {
            formData.append(`files`, file);
          });
        }

        console.log(formData, "formData");

        const addForm = await requestForm(formData);
        console.log(addForm, "addForm");

        const { success, message } = addForm;

        if (success == true) {
          toast.success(message);
          // setForm({});
          setForm({
            first_name: "",
            last_name: "",
            email: "",
            pre_mobile: "",
            // mobile: "",
            service: "",
            budget: "",
            start_at: "",
            requirement: "",
            description: "",
          });
          setFormErrors({});
          setSelectedFiles([]);
          setLoading(false);
          // console.log(message, "message");
        } else {
          toast.error(message);
          setLoading(false);
          // setForm({});
          // console.log(message, "message");
        }
      }else{
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(loading, "11111111111111111111");
  // [
  // 'first_name' => 'required|max:191',
  // 'last_name' => 'required|max:191',
  // 'email' => 'required|max:191|email|regex:/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}/',
  // 'pre_mobile' => 'required|max:191',
  // 'mobile' => 'required|max:191',
  // 'service' => 'required|max:191',
  // 'budget' => 'required|max:191',
  // 'start_at' => 'required|max:191',
  // 'requirement' => 'required|max:191',
  // 'description' => 'max:500',
  //   ]

  const handleFileChange = (event) => {
    const filesArray = Array.from(event.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...filesArray]);
  };

  const handlePhoneNumberChange = (phoneNumber, country) => {
    const countryCode = country.dialCode;
    let number = countryCode.length;
    setForm({
      ...form,
      ["mobile"]: phoneNumber.slice(number),
      ["pre_mobile"]: "+" + countryCode,
    });
  };

  return (
    <>
      <div className="request-form contact_form">
        <Container>
          {/* <div
            id="zf_div_MsOqjiUmtnHE55Djqqo1lu3Kni6_6rDKWxSZ5RD4ZLA"
            className="text-center"
          ></div> */}
          <div className="quote-form position-relative">
          {loading && (
                        <div className="loader_form d-flex align-items-center justify-content-center">
                          <img src={Loader} />
                        </div>
                      )}
                <div className="d-flex align-items-center justify-content-between quote-input-main ">
                  <label>
                    Name <span>*</span>
                  </label>
                  <div className="input-main d-flex align-items-start gap-3">
                    <div className="w-100">
                      <input
                        type="text"
                        className="w-100"
                        placeholder="First Name"
                        name="first_name"
                        onChange={handleChange}
                        value={form.first_name}
                      />
                      {formErrors.first_name && (
                        <span className="error">{formErrors.first_name}</span>
                      )}
                    </div>
                    <div className="w-100">
                      <input
                        type="text"
                        className="w-100"
                        placeholder="Last Name"
                        name="last_name"
                        onChange={handleChange}
                        value={form.last_name}
                      />
                      {formErrors.last_name && (
                        <span className="error">{formErrors.last_name}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between quote-input-main mt-4 mt-sm-4">
                  <label>
                    Email <span>*</span>
                  </label>
                  <div className="input-main">
                    <input
                      type="text"
                      className="w-100"
                      placeholder="Enter Your Valid Email"
                      name="email"
                      value={form.email}
                      onChange={handleChange}
                    />
                    {formErrors.email && (
                      <span className="error">{formErrors.email}</span>
                    )}
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between quote-input-main mt-4 mt-sm-4">
                  <label>
                    Mobile Number <span>*</span>
                  </label>
                  <div className="input-main">
                    {/* <input
                    type="number"
                    className="w-100"
                    placeholder="Mobile Number"
                    name="mobile"
                    onChange={handleChange}
                  /> */}
                    <PhoneInput
                      country={"in"}
                      enableSearch={true}
                      value={form?.pre_mobile?.concat(form?.mobile)}
                      onChange={handlePhoneNumberChange}
                      style={{ width: "100%" }}
                    />
                    {formErrors.mobile && (
                      <span className="error">{formErrors.mobile}</span>
                    )}
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between quote-input-main mt-4 mt-sm-4">
                  <label>
                    Select Service <span>*</span>
                  </label>
                  <div className="input-main ">
                    <select
                      className="w-100"
                      onChange={handleChange}
                      name="service"
                      value={form.service}
                    >
                      <option value="">-Select-</option>
                      <option>Mobile Development</option>
                      <option>Website Development</option>
                      <option>Game Development</option>
                      <option>UI/UX Design</option>
                      <option>Web Design</option>
                      <option>Logo Design</option>
                      <option>2D/3D Animation</option>
                    </select>
                    {formErrors.service && (
                      <span className="error">{formErrors.service}</span>
                    )}
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between quote-input-main mt-4 mt-sm-4">
                  <label>
                    Budget <span>*</span>
                  </label>
                  <div className="input-main ">
                    <select
                      className="w-100"
                      onChange={handleChange}
                      name="budget"
                      value={form.budget}
                    >
                      <option value="">-Select-</option>
                      <option>Less than $5000</option>
                      <option>$5000-$20000</option>
                      <option>$20000-$50000</option>
                      <option>$50000-$100000</option>
                      <option>More than $100000</option>
                    </select>
                    {formErrors.budget && (
                      <span className="error">{formErrors.budget}</span>
                    )}
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between quote-input-main mt-4 mt-sm-4">
                  <label>
                    How Soon You Want To Start? <span>*</span>
                  </label>
                  <div className="input-main">
                    <select
                      className="w-100"
                      onChange={handleChange}
                      name="start_at"
                      value={form.start_at}
                    >
                      <option value="">-Select-</option>
                      <option>From Tomorrow</option>
                      <option>After 7 Days</option>
                      <option>After 15 Days</option>
                      <option>After 20 Days</option>
                      <option>After a Month</option>
                    </select>
                    {formErrors.start_at && (
                      <span className="error">{formErrors.start_at}</span>
                    )}
                  </div>
                </div>

                <div className="d-flex align-items-start justify-content-between quote-input-main mt-4 mt-sm-4">
                  <label>
                    Requirement <span>*</span>
                  </label>
                  <div className="input-main ">
                    <div className="input-main d-flex align-items-center gap-3 gap-sm-5 align-start-cos">
                      <div className="requirement-select">
                        <div className="d-flex align-items-center gap-3 radio-select">
                          <input
                            id="demo"
                            type="radio"
                            name="requirement"
                            value={"Support - Maintenance"}
                            checked={
                              form.requirement == "Support - Maintenance"
                            }
                            onChange={handleChange}
                          />
                          <label htmlFor="demo">Support - Maintenance</label>
                        </div>

                        <div className="d-flex align-items-center gap-3 radio-select mt-3">
                          <input
                            id="demo1"
                            type="radio"
                            name="requirement"
                            value={"Existing Project"}
                            checked={form.requirement == "Existing Project"}
                            onChange={handleChange}
                          />
                          <label htmlFor="demo1">Existing Project</label>
                        </div>
                      </div>
                      <div className="requirement-select">
                        <div className="d-flex align-items-center gap-3 radio-select">
                          <input
                            id="demo2"
                            type="radio"
                            name="requirement"
                            value={"New Project"}
                            checked={form.requirement == "New Project"}
                            onChange={handleChange}
                          />
                          <label htmlFor="demo2">New Project</label>
                        </div>

                        <div className="d-flex align-items-center gap-3 radio-select mt-3">
                          <input
                            id="demo3"
                            type="radio"
                            name="requirement"
                            value={"Hire Dedicated Team"}
                            checked={form.requirement == "Hire Dedicated Team"}
                            onChange={handleChange}
                          />
                          <label htmlFor="demo3">Hire Dedicated Team</label>
                        </div>
                      </div>
                    </div>
                    {formErrors.requirement && (
                      <span className="error">{formErrors.requirement}</span>
                    )}
                  </div>
                </div>

                <div className="d-flex align-items-start justify-content-between quote-input-main mt-4 mt-sm-4">
                  <label>Brief Project Description</label>
                  <div className="input-main d-flex align-items-center gap-3">
                    <textarea
                      className="w-100"
                      placeholder="Brief Project Description"
                      rows={4}
                      name="description"
                      value={form.description}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="d-flex align-items-start justify-content-between  quote-input-main mt-4 mt-sm-4">
                  <label>Want to Share a file?</label>
                  <div className="input-main upload-file-cos">
                    <input
                      type="file"
                      id="choose-file"
                      multiple
                      onChange={handleFileChange}
                    />
                    <label className="w-100" htmlFor="choose-file">
                      Choose file
                    </label>
                    {selectedFiles.length > 0 && (
                      <Row className=" selected-file mx-0">
                        {selectedFiles.map((file, index) => (
                          <Col
                            lg={6}
                            md={6}
                            sm={12}
                            key={index}
                            className="mt-2"
                          >
                            <div className="selected-file-box d-flex align-items-center justify-content-between">
                              <div className="select-info d-flex align-items-center gap-2">
                                <img
                                  src={
                                    file.type.startsWith("image/")
                                      ? URL.createObjectURL(file)
                                      : image
                                  }
                                  alt="file"
                                />
                                <div className="select-file-text">
                                  <p>{file.name}</p>
                                  <span>{Math.round(file.size / 1024)} kb</span>
                                </div>
                              </div>
                              <Button
                                onClick={() => {
                                  const newData = [...selectedFiles];
                                  newData.splice(index, 1);
                                  setSelectedFiles(newData);
                                }}
                              >
                                <IoMdClose />
                              </Button>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    )}
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center mt-5">
                  <Button className="submit-form-cos" onClick={onSubmit}>
                    Submit
                  </Button>
                </div>
          
          </div>
        </Container>
      </div>
    </>
  );
};

export default RequestForm;

