import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-bootstrap";
import allApiData from '../../api';

const GetInTought = () => {
  const [footerData, setFooterData] = useState([]);
  useEffect(() => {
    allApiData.getHomeApi().then((response) => {
      setFooterData(response.footer_settings)
    });
  }, [])


  return (
    <>
      <div className="get_in_tought">
        <Container>
          <div className="get_in_tought_main">
            <h4>
              Get In Touch With Our Experts To Turn Your Idea Into Reality.
            </h4>
            <Row className="justify-content-center">
              <Col lg={4} md={6} sm={12} className="border-right-getbox mt-5">
                <a href={footerData.skype} target="_blank">

                  <div className="get_in_tought-box">
                    <div className="get-img">
                      <img
                        src="../assets/img/request-of-quote/get/skype.svg"
                        alt="skype"
                      />
                    </div>
                    <div className="get-box">
                      <div><h4>Skype</h4></div>
                      <NavLink href={footerData.skype} target="_blank">
                        {footerData.skype}
                      </NavLink>
                    </div>
                  </div>
                </a>
              </Col>
              <Col lg={4} md={6} sm={12} className="border-right-getbox2 mt-sm-5 mt-4">
                <a href={`mailto:${footerData.email1}`}>
                  <div className="get_in_tought-box">
                    <div className="get-img">
                      <img
                        className="mt-3"
                        src="../assets/img/request-of-quote/get/email.svg"
                        alt="email"
                      />
                    </div>
                    <div className="get-box">
                      <div><h4>Email Id</h4></div>
                      <NavLink href={`mailto:${footerData.email1}`}>
                        {footerData.email1}
                      </NavLink>
                    </div>
                  </div>
                </a>
              </Col>
              <Col lg={4} md={6} sm={12} className="mt-5">
                <a href={`tel:+${footerData.mobile1}`}>
                  <div className="get_in_tought-box">
                    <div className="get-img">
                      <img
                        src="../assets/img/request-of-quote/get/phone.svg"
                        alt="phone"
                      />
                    </div>
                    <div className="get-box">
                      <div><h4>Phone</h4></div>
                      {/* {
                        footerData && footerData.mobiles.map((_mobile) => {
                          return (
                          )
                        })} */}
                      {/* <NavLink href={`tel:+${footerData.mobile1}`}>
                        {footerData.mobile1}
                      </NavLink> */}
                      {
                        footerData.mobile1 !== null &&
                        <span className="mb-0">
                          <NavLink className="d-block pb-0" href={`tel:+${footerData.mobile1}`}>
                            {footerData.mobile1 !== 'null' ? footerData.mobile1 : ""}
                          </NavLink>
                          {
                            footerData.mobile2 !== 'null' &&
                            <NavLink className="d-block py-0" href={`tel:+${footerData.mobile2}`}>
                              {footerData.mobile2 !== 'null' ? footerData.mobile2 : ""}
                            </NavLink>
                          }
                        </span>
                      }
                    </div>
                  </div>
                </a>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default GetInTought;
